import React, { useEffect, useState } from "react";

interface ProgressIndicatorProps {
  isActive: boolean;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ isActive }) => {
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [message, setMessage] = useState<string>("Generating...");

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;

    if (isActive) {
      setElapsedTime(0);
      setMessage("Generating...");
      
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => {
          const newTime = prevTime + 1;
          
          // Update message based on elapsed time
          if (newTime >= 60) {
            setMessage("Bear with us, this is taking longer than expected...");
          } else if (newTime >= 40) {
            setMessage("Almost there...");
          } else if (newTime >= 20) {
            setMessage("Thinking...");
          }
          
          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isActive]);

  if (!isActive) return null;

  // Calculate progress percentage (max 100%)
  const progressPercentage = Math.min((elapsedTime / 60) * 100, 100);

  return (
    <div className="mt-1 w-full">
      <div className="flex justify-between mb-1">
        <div className="flex items-center">
          <div className="mr-3 relative w-6 h-6 flex items-center justify-center">
            <div className="animate-spin" style={{ animationDuration: '3s' }}>
              <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="starGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stopColor="#02C389" />
                    <stop offset="100%" stopColor="#00A3FF" />
                  </linearGradient>
                </defs>
                <path d="M12 0L14.59 8.41L23 12L14.59 15.59L12 24L9.41 15.59L1 12L9.41 8.41L12 0Z" fill="url(#starGradient)" />
              </svg>
            </div>
          </div>
          <span className="text-sm font-medium text-gray-700">{message}</span>
        </div>
        <span className="text-sm font-medium text-gray-700">{elapsedTime}s</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-[#02C389] h-2.5 rounded-full transition-all duration-300 ease-in-out" 
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressIndicator; 