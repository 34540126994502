import { dataFetcher } from "common/api";
import promptsApi from "common/api/PromptsApi";
import { RootState } from "store";
import { Document } from "common/api/interfaces";

interface DocumentsModule {
  fetchDocuments: () => Promise<any>;
  documentsFetcher: any;
  documentsSelector: (state: RootState) => Document[];
}

export const createDocumentsModule = (): DocumentsModule => {
  const fetchDocuments = async () => {
    return await promptsApi.getDocuments();
  };

  return {
    fetchDocuments,
    documentsFetcher: dataFetcher(fetchDocuments, 'documents'),
    documentsSelector: (state: RootState) => state.data.documents?.result || []
  };
}; 