import { createUsersModule } from "common/users"
import { useSelector } from "react-redux"
import { Dropdown, Tooltip } from "flowbite-react"
import { useEffect, useState } from "react"
import { Assistant } from "common/api/interfaces"
import { createAssistantModule } from "./modules"
import InformationIcon from "components/icons/InformationIcon"
import { useFlags } from "launchdarkly-react-client-sdk"

interface SelectAssistantProps {
  onSelect: (assistant: Assistant) => void;
  disabled?: boolean;
}

const SelectAssistant: React.FC<SelectAssistantProps> = ({ onSelect, disabled = false }) => {
  const usersModule = createUsersModule()
  const profile = useSelector(usersModule.profileSelector)
  const [assistants, setAssistants] = useState<Assistant[]>(null)
  const [label, setLabel] = useState<string>('General Assistant')
  const assistantModule = createAssistantModule()
  const { aiPromptlyFinancialWorklogDocumentChat } = useFlags()

  useEffect(() => {
    let availableAssistants = profile.settings.available_assistants
    console.log(`Is aiPromptlyFinancialWorklogDocumentChat enabled: ${aiPromptlyFinancialWorklogDocumentChat}`)
    if (!aiPromptlyFinancialWorklogDocumentChat) {
      availableAssistants = availableAssistants.filter(assistant =>
        !['claim_analyst', 'document_analyst'].includes(assistant.id)
      )
    }
    setAssistants(availableAssistants)
  }, [profile, aiPromptlyFinancialWorklogDocumentChat])

  const onAssistantChange = (assistant: Assistant) => {
    let assistantId = assistant.id
    if (assistant.name !== label) {
      if (assistantId === 'none') {
        assistantId = null
      }
      assistantModule.updateAssistant(assistantId, null)
      onSelect(assistant)
    }
    setLabel(assistant.name)
    console.log(assistant.name)
  }
  return assistants &&
    <div className="flex gap-2 items-center">
      <label htmlFor="assistants" className="text-lg font-bold"> Assistant</label>
      <div className={`${disabled ? 'opacity-60 cursor-not-allowed' : ''}`}>
        {disabled ? (
          <Tooltip content="Assistant selection is disabled while a prompt is running">
            <div>
              <Dropdown label={<div className="text text-md">{label}</div>} inline disabled={disabled}>
                {assistants.map((assistant) => {
                  const menuItem = <Dropdown.Item key={assistant.id} onClick={() => onAssistantChange(assistant)} className="w-full">
                    <div className="flex w-full justify-between items-center gap-2">
                      <div className="text w-full text-left">
                        {assistant.name}
                      </div>
                      {assistant.description && <Tooltip content={
                        <div className="flex justify-start text-left">
                          <div dangerouslySetInnerHTML={{ __html: assistant.description }} />
                        </div>
                      } className="w-full">
                        <InformationIcon />
                      </Tooltip>}
                    </div>
                  </Dropdown.Item>
                  return menuItem
                })}
              </Dropdown>
            </div>
          </Tooltip>
        ) : (
          <Dropdown label={<div className="text text-md">{label}</div>} inline disabled={disabled}>
            {assistants.map((assistant) => {
              const menuItem = <Dropdown.Item key={assistant.id} onClick={() => onAssistantChange(assistant)} className="w-full">
                <div className="flex w-full justify-between items-center gap-2">
                  <div className="text w-full text-left">
                    {assistant.name}
                  </div>
                  {assistant.description && <Tooltip content={
                    <div className="flex justify-start text-left">
                      <div dangerouslySetInnerHTML={{ __html: assistant.description }} />
                    </div>
                  } className="w-full">
                    <InformationIcon />
                  </Tooltip>}
                </div>
              </Dropdown.Item>
              return menuItem
            })}
          </Dropdown>
        )}
      </div>
    </div>
}

export { SelectAssistant }