import { useEffect, useState, useRef } from "react";
import { Header } from "./Header"
import AuthenticationHandler from "components/AuthenticationHandler";
import { useSelector } from "react-redux";
import { Spinner } from "flowbite-react";
import { createUsersModule } from "common/users";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { profileFetcher } from "common/api/UsersApi";
import CONFIG from 'config';

// Wrapper component that either uses LaunchDarkly provider or renders children directly
const FeatureFlagProvider: React.FC<{ provider: React.ComponentType<any> | null; children: React.ReactNode }> = ({
  provider: Provider,
  children
}) => {
  if (Provider) {
    return <Provider>{children}</Provider>;
  }
  // Render children without LaunchDarkly when provider is not available
  return <>{children}</>;
};

const DashboardLayout: React.FC<any> = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const origin = queryParams.get("origin");
  const [LDProvider, setLDProvider] = useState<React.ComponentType<any> | null>(null);
  const ldInitialized = useRef(false);

  const usersModule = createUsersModule()
  const profile = useSelector(usersModule.profileSelector)

  useEffect(() => {
    const fetchProfile = async () => {
      if (!code && !origin) {
        try {
          await profileFetcher.fetch();
        } catch (error) {
          console.error('Failed to fetch profile:', error);
        }
      }
    };

    fetchProfile();
  }, [code, origin]);

  useEffect(() => {
    const initializeLDProvider = async () => {
      if (profile?.user && !ldInitialized.current) {
        const contextKey = `aip#${profile.tenant.id}#${profile.user.id}`;
        ldInitialized.current = true;
        try {
          const userFullName = `${profile.user.first_name} ${profile.user.last_name}`;
          const userContext = {
            key: contextKey,
            name: userFullName,
            email: profile.user.email,
            custom: {
              tenant_id: profile.tenant.id,
              user_id: profile.user.id,
            },
          };
          console.log(`[LaunchDarkly] User context:`);
          console.log(userContext)
          const Provider = await asyncWithLDProvider({
            clientSideID: CONFIG.LAUNCH_DARKLY_CLIENT_SIDE_ID,
            reactOptions: {
              useCamelCaseFlagKeys: true,
            },
            user: userContext,
          });
          setLDProvider(() => Provider);
        } catch (error) {
          console.error('Failed to initialize LaunchDarkly:', error);
          // Continue without LaunchDarkly
        }
      }
    };

    initializeLDProvider();
  }, [profile]);

  return <div className="w-full space-y-2 bg-[#F9FDFC] pb-[200px]">
    <AuthenticationHandler />

    <div className="flex flex-wrap mx-auto max-w-[1246px] pt-4">
      <div className="w-full">
        <Header />
      </div>
      <hr className="w-full my-4 border-t border-[#E5E9EE]"></hr>
    </div>
    {
      profile ?
        <FeatureFlagProvider provider={LDProvider}>
          <div className="w-full flex mx-auto max-w-[1246px] pt-4 bg-[#F9FDFC]">
            {children}
          </div>
        </FeatureFlagProvider>
        : <div>
          <Spinner />
        </div>
    }
  </div>
}

export default DashboardLayout;