const CONFIG = {
  APP_NAME: "AI-Promptly",
  API_URL: process.env.REACT_APP_API_URL,
  GLOBAL_PROMPTS_LIMIT: 20,
  PROMPTS_LIMIT: 10,
  USERBACK_TOKEN: process.env.REACT_APP_USERBACK_TOKEN,
  LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID
};

export default CONFIG;
