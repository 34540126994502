import { dataFetcher } from "common/api";
import promptsApi from "common/api/PromptsApi";
import { RootState } from "store";

interface ClaimsModule {
  fetchClaimFiscalPeriods: () => Promise<any>;
  claimFiscalPeriodsFetcher: any;
  fiscalPeriodsSelector: (state: RootState) => any;
}

export const createClaimsModule = (): ClaimsModule => {
  const fetchClaimFiscalPeriods = async () => {
    return await promptsApi.getClaimFiscalPeriods();
  };

  return {
    fetchClaimFiscalPeriods,
    claimFiscalPeriodsFetcher: dataFetcher(fetchClaimFiscalPeriods, 'claimFiscalPeriods'),
    fiscalPeriodsSelector: (state: RootState) => state.data.claimFiscalPeriods?.result
  };
}; 