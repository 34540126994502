import promptsApi, { fetchGlobalPrompts, globalPromptsFetcher } from 'common/api/PromptsApi';
import HighlightText from 'components/HighlightText';
import InfiniteScroll from 'components/InfiniteScroll';
import SearchInput from 'components/SearchInput';
import { Table, Rating, Avatar, Tooltip, Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'store';
import { updateSearchTerm, updateSkip } from 'store/globalPromptsSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';

const GlobalPrompts: React.FC<any> = ({ onSelect }) => {
  const { result, isLoading } = useSelector((state: any) => state.data?.globalPrompts || {})
  // const { totalCount, skip, limit } = useSelector((state: any) => state?.globalPrompts || { totalCount: 0, skip: 0, limit: 0 })
  const [globalPrompts, setGlobalPrompts] = useState<any[]>([])
  const [searchTerm, setSearchTerm] = useState<string>()
  const { aiPromptlyFinancialWorklogDocumentChat } = useFlags()


  useEffect(() => {
    // console.log('new global prompts result')
    // result && console.log(result.results)
    if (result) {
      let filteredPrompts = result.results;
      
      // Filter out prompts related to document_analyst and claim_analyst assistants if flag is not enabled
      if (!aiPromptlyFinancialWorklogDocumentChat) {
        filteredPrompts = filteredPrompts.filter((prompt: any) => 
          !(prompt.assistant && ['claim_analyst', 'document_analyst'].includes(prompt.assistant.id))
        );
      }
      
      setGlobalPrompts(filteredPrompts);
    }
  }, [result, aiPromptlyFinancialWorklogDocumentChat])

  useEffect(() => {

  }, [])
  const fetchData = async () => {
    const { totalCount, skip, limit } = store.getState().globalPrompts
    // console.log('scroll global prompts')
    // console.log(totalCount, skip, limit)
    const nextSkip = skip + limit
    // console.log(`scroll global prompts, nextSkip=${nextSkip}`)

    if (nextSkip < totalCount) {
      store.dispatch(updateSkip(nextSkip))
      console.log(`fetch global prompts, nextSkip=${nextSkip}`)
      const res = await fetchGlobalPrompts()
      setGlobalPrompts((prevGlobalPrompts) => {
        let newGlobalPrompts = [...prevGlobalPrompts, ...res.data.results];
        
        // Filter out prompts related to document_analyst and claim_analyst assistants if flag is not enabled
        if (!aiPromptlyFinancialWorklogDocumentChat) {
          newGlobalPrompts = newGlobalPrompts.filter((prompt: any) => 
            !(prompt.assistant && ['claim_analyst', 'document_analyst'].includes(prompt.assistant.id))
          );
        }
        
        return newGlobalPrompts;
      });
    } else {
      console.log('nothing to scroll...')
    }
  }
  const handleSearch = async (searchTerm: string) => {
    console.log(`Search global prompts: ${searchTerm}`)
    store.dispatch(updateSearchTerm(searchTerm))
    store.dispatch(updateSkip(0))
    globalPromptsFetcher.fetch()
    setSearchTerm(searchTerm)
  }
  return <div className="relative w-full ml-auto 
    rounded rounded-md ">
    <div className="w-full ml-auto 
    rounded rounded-md ">
      <div className="w-full flex justify-between flex-wrap items-center p-2">
        <div className="w-full  md:max-w-[200px] text font-bold text-sm md:text-xl pt-1 pe-1 pb-1 uppercase">Global Prompts</div>
        <div className="w-full md:max-w-[300px]">
          <SearchInput onSearch={handleSearch} />
        </div>
      </div>

      <InfiniteScroll className="w-full flex flex-col gap-4 
            max-h-[260px] min-h-[260px]
            rounded rounded-md p-4
            shadow mb-2
            overflow-auto bg-white h-full"
        fetchData={fetchData}
        paginationSelector={(state: any) => state.globalPrompts}
      >
        {globalPrompts && globalPrompts.map((prompt: any, index: number) => {
          const rating = Math.round(prompt.rating)
          const starComponents = [];
          for (let i = 0; i < 5; i++) {
            starComponents.push(<Rating.Star key={i} filled={i < rating ? true : false} />);
          }
          return <div className="flex flex-col w-full gap-2 text-left
                hover:cursor-pointer"
            key={`prompt-${index}`}
            onClick={() => onSelect(prompt)}>
            <div className="text text-sm w-full">
              <div className="w-full flex justify-between">
                <span className="text font-bold pe-2">
                  Prompt:
                </span>
                {prompt?.assistant &&
                  <div className="flex gap-2">
                    <span className="text font-bold">
                      Assistant:
                    </span>
                    <span className="text text-blue-700 font-bold">
                      {prompt?.assistant?.name}
                    </span>
                  </div>
                }
              </div>
              <HighlightText text={prompt?.query} searchTerm={searchTerm} />
            </div>
            {/* <hr className="w-full my-1 border-t border-[#E5E9EE]"></hr> */}
            <div className='flex gap-2 items-center w-full justify-between'>
              <div className='flex gap-2 items-center'>
                <Rating>{starComponents}</Rating>

              </div>
              <div className='font-bold text-sm'>{prompt?.rating?.toFixed(2)} / 5 ({prompt?.ratings_count})</div>
              {prompt.username && <div className='flex gap-2 items-center'>
                <Tooltip content={prompt?.username}>
                  <div className='text-sm max-w-[100px] truncate'>{prompt?.username}</div>
                </Tooltip>
                <Avatar rounded size="xs" />
              </div>}

            </div>
            <hr className="w-full my-1 border-t border-[#E5E9EE]"></hr>

          </div>
        })}
      </InfiniteScroll>
    </div>
    {isLoading && (
      <div className="absolute inset-0 pe-2 flex items-center justify-center bg-white bg-opacity-75 z-10">
        <Spinner color="success" />
      </div>
    )}
  </div>
}

export default GlobalPrompts;