import React, { useMemo, useState } from 'react';
import { Badge, Checkbox, Label, TextInput } from 'flowbite-react';

export interface MultiSelectProps<T> {
  label: string;
  value: T[];
  options: T[];
  maxItems?: number;
  onChange: (value: T[]) => void;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string | number;
  renderOption?: (option: T) => React.ReactNode;
  error?: string;
  disabled?: boolean;
}

export const MultiSelect = <T,>({
  label,
  value,
  options,
  maxItems,
  onChange,
  getOptionLabel,
  getOptionValue,
  renderOption,
  error,
  disabled = false
}: MultiSelectProps<T>) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const selectedValues = useMemo(() => 
    value.map(item => getOptionValue(item)),
    [value, getOptionValue]
  );

  const filteredOptions = useMemo(() => {
    if (!searchTerm) return options;
    return options.filter(option => 
      getOptionLabel(option).toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm, getOptionLabel]);

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleBadgeClick = (item: T) => {
    if (disabled) return;
    const newValue = value.filter(val => getOptionValue(val) !== getOptionValue(item));
    onChange(newValue);
  };

  const handleCheckboxChange = (option: T) => {
    if (disabled) return;

    const isSelected = selectedValues.includes(getOptionValue(option));
    let newValue: T[];
    
    if (isSelected) {
      newValue = value.filter(item => getOptionValue(item) !== getOptionValue(option));
    } else {
      if (maxItems && value.length >= maxItems) {
        return;
      }
      newValue = [...value, option];
    }
    
    onChange(newValue);
  };

  const handleSelectAll = () => {
    if (disabled) return;
    
    const optionsToSelect = filteredOptions;
    
    if (value.length === optionsToSelect.length || (maxItems && value.length === maxItems)) {
      // If all are selected or we've hit the max, deselect all
      onChange([]);
    } else {
      // Select all (up to maxItems if specified)
      const newValue = maxItems ? optionsToSelect.slice(0, maxItems) : optionsToSelect;
      onChange(newValue);
    }
  };

  // Determine if "Select All" or "Deselect All" should be shown
  const isAllSelected = useMemo(() => {
    // If there are no filtered options, we shouldn't show "Deselect All"
    if (filteredOptions.length === 0) return false;
    
    // If we have a search term and all filtered options are selected
    if (searchTerm && filteredOptions.every(option => 
      selectedValues.includes(getOptionValue(option)))) {
      return true;
    }
    
    // If no search and all options are selected or we've hit max items
    if (!searchTerm && (
      (maxItems && value.length >= maxItems) || 
      value.length === options.length
    )) {
      return true;
    }
    
    return false;
  }, [filteredOptions, searchTerm, selectedValues, getOptionValue, maxItems, value.length, options.length]);

  return (
    <div className="w-full">
      <div className="w-full flex justify-between p-2">
        <span className="text font-bold text-sm md:text-xl p-1 uppercase dark:text-white">
          {label}
          {maxItems && (
            <span className="ml-2 text-sm text-gray-500 normal-case">
              ({selectedValues.length}/{maxItems})
            </span>
          )}
        </span>
      </div>
      
      {/* Selected Items */}
      <div className="flex flex-wrap gap-2 mb-2">
        {value.map((item) => (
          <Badge 
            key={getOptionValue(item)}
            color="success"
            className="cursor-pointer bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-300 whitespace-normal break-words max-w-full"
            onClick={() => handleBadgeClick(item)}
          >
            {getOptionLabel(item)}
            {!disabled && (
              <button
                type="button"
                className="ml-1.5 inline-flex items-center p-0.5 text-sm bg-transparent rounded-sm hover:bg-green-200 dark:hover:bg-green-800"
                aria-label="Remove"
              >
                ×
              </button>
            )}
          </Badge>
        ))}
      </div>

      {/* Options List */}
      <div className="h-60 overflow-y-auto border border-gray-300 rounded-lg p-2 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
        <div className="flex flex-col gap-2">
          {/* Select All Option and Search */}
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-600 pb-2 mb-2">
            <div className="flex items-center gap-2">
              <Checkbox
                id="select-all"
                checked={isAllSelected}
                onChange={handleSelectAll}
                disabled={disabled}
                className="focus:ring-green-500 text-green-500"
              />
              <Label
                htmlFor="select-all"
                disabled={disabled}
                className="font-medium"
              >
                {isAllSelected ? 'Deselect All' : 'Select All'}
                {maxItems && ` (up to ${maxItems})`}
              </Label>
            </div>
            
            {/* Search Input */}
            <div className="w-1/2 relative">
              <TextInput
                id="search-options"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sizing="sm"
                className="focus:ring-green-500 focus:border-green-500"
              />
              {searchTerm && (
                <button
                  type="button"
                  className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 focus:outline-none"
                  onClick={clearSearch}
                  aria-label="Clear search"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
            </div>
          </div>

          {filteredOptions.map((option) => {
            const optionValue = getOptionValue(option);
            const isSelected = selectedValues.includes(optionValue);
            const isDisabled = disabled || (maxItems ? value.length >= maxItems && !isSelected : false);
            
            return (
              <div key={optionValue} className="flex items-center gap-2">
                <Checkbox
                  id={String(optionValue)}
                  checked={isSelected}
                  onChange={() => handleCheckboxChange(option)}
                  disabled={isDisabled}
                  className="focus:ring-green-500 text-green-500"
                />
                <Label
                  htmlFor={String(optionValue)}
                  disabled={isDisabled}
                  className={`${isDisabled ? 'text-gray-400' : ''} whitespace-normal break-words`}
                >
                  {renderOption ? renderOption(option) : getOptionLabel(option)}
                </Label>
              </div>
            );
          })}
          
          {filteredOptions.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No results found
            </div>
          )}
        </div>
      </div>

      {error && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {error}
        </p>
      )}
    </div>
  );
}; 