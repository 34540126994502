import { useEffect, useRef, useState } from "react";
import { Spinner } from "flowbite-react";
import { ErrorToast } from "components/toasts";
import ProgressIndicator from "./ProgressIndicator";
import { useFlags } from "launchdarkly-react-client-sdk";
import promptsApi from "common/api/PromptsApi";
import { v4 as uuidv4 } from 'uuid';

const RunPrompt: React.FC<any> = ({ onChange, onRun, enabled, prompt }) => {
    const [isSubmit, setIsSubmit] = useState<boolean>()
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const opacity = isEnabled ? '' : 'opacity-50'
    const [error, setError] = useState<string>()
    const [isPolling, setIsPolling] = useState<boolean>(false)
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { aiPromptlyFinancialWorklogDocumentChat } = useFlags();

    useEffect(() => {
        // console.log(`is run enabled ${enabled}`)
        // console.log(`opacity ${opacity}`)
        setIsEnabled(enabled)
    }, [enabled])

    // Generate a unique user_request_id
    const generateUserRequestId = () => {
        return uuidv4();
    };

    // Poll for prompt completion
    const pollForCompletion = async (userRequestId: string) => {
        setIsPolling(true);
        let attempts = 0;
        const maxAttempts = 12; // 12 attempts * 5 seconds = 60 seconds
        const pollInterval = 5000; // 5 seconds

        const checkCompletion = async () => {
            try {
                const response = await promptsApi.isPromptCompleted(userRequestId);
                if (response.data.completed) {
                    setIsPolling(false);
                    setIsSubmit(false);
                    setIsEnabled(true);
                    // Refresh prompts list to show the new result
                    await onRun(true);
                    return true;
                }
            } catch (err) {
                console.error("Error checking prompt completion:", err);
            }
            
            attempts++;
            if (attempts >= maxAttempts) {
                setIsPolling(false);
                setIsSubmit(false);
                setIsEnabled(true);
                setError("The request is taking longer than expected. The result may appear later when it completes processing.");
                return true;
            }
            
            return false;
        };

        const poll = async () => {
            const completed = await checkCompletion();
            if (!completed) {
                setTimeout(poll, pollInterval);
            }
        };

        await poll();
    };

    // Custom textarea with controlled styling
    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange(e);
        }
    };

    return <div className="w-full flex flex-col gap-4">
        {/* Custom styled textarea container */}
        <div className="w-full border border-gray-300 rounded-lg overflow-hidden bg-white focus-within:ring-2 focus-within:ring-[#02C389] focus-within:border-[#02C389] dark:focus-within:ring-[#02C389] dark:focus-within:border-[#02C389] transition-all duration-200 p-2.5">
            <textarea 
                ref={textareaRef}
                id="prompt" 
                rows={4} 
                className="w-full h-full text-sm text-gray-900 focus:outline-none"
                style={{ 
                    padding: '0', 
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    resize: 'vertical',
                    backgroundColor: 'transparent'
                }}
                placeholder="Enter prompt..."
                onChange={handleTextareaChange}
                value={prompt}
            ></textarea>
        </div>

        <button className={`p-4 text-center text-white 
        bg-[#02C389] font-bold rounded rounded-[15px]
        space-x-2
        ${opacity}`}
            onClick={async () => {
                setIsSubmit(true)
                setIsEnabled(false)
                setError(undefined)
                
                // Generate a unique user_request_id for this request
                const userRequestId = generateUserRequestId();
                
                try {
                    // Pass the user_request_id to onRun
                    await onRun(false, userRequestId);
                } catch (err: any) {
                    console.log(err);
                    if (err?.response?.status === 429) {
                        setError(`We're extremely busy at this time. 
                        Please try again later or contact administrator if the error persists.`)
                    } else if (err?.response?.status == 502) {
                        setError(`Service overloaded or not ready.
                        Please try again later or contact administrator if the error persists.`)
                    } else if (err?.response?.status === 504) {
                        // For 504 errors, start polling for completion
                        console.log(`Received ${err?.response?.status}, starting polling for completion...`);
                        pollForCompletion(userRequestId);
                        return; // Don't reset state yet, polling will handle it
                    } else if (err?.response?.status === 400) {
                        setError(err.response.data.error)
                    } else {
                        setError(`Could not run prompt. Please try again or contact administrator if the error persists.`)
                    }
                    setIsSubmit(false)
                    setIsEnabled(true)
                }
                
                if (!isPolling) {
                    setIsSubmit(false)
                    setIsEnabled(true)
                }
            }
            }
            disabled={!isEnabled || isSubmit}>
            {isSubmit && <Spinner />}<span>{isPolling ? "Processing..." : "Run Prompt"}</span>
        </button>
        
        <ProgressIndicator isActive={isSubmit} />
        
        {error && <ErrorToast message={error} onClose={() => {
            console.log('Closing error...')
            setError('')
        }} />}

    </div>

}

export default RunPrompt;