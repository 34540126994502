import UploadFileIcon from "components/icons/UpoloadFileIcon"
import { ErrorToast } from "components/toasts"
import { Spinner, Badge } from "flowbite-react"
import { useEffect, useRef, useState } from "react"
import { createPromptsModule } from "./modules"
import { debounce } from "lodash"
import { MultiSelect } from "components/MultiSelect"
import { useSelector } from "react-redux"
import { createAssistantModule } from "components/assistants/modules"
import { createClaimsModule } from "components/claims/modules"
import { createDocumentsModule } from "components/documents/modules"
import { ClaimFiscalPeriod, Document } from "common/api/interfaces"
import { AssistantState } from "store/assistantSlice"
import { RootState } from "store"

interface ContextDataProps {
  onChange: (e: any) => void;
  onUpload: (files: FileList | null) => Promise<void>;
  contextData?: string;
  tokensCount?: number;
  isRunning?: boolean;
}

// Add RefreshIcon component
const RefreshIcon = () => (
  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
  </svg>
);

const ContextData: React.FC<ContextDataProps> = ({ onChange, onUpload, contextData, tokensCount, isRunning = false }) => {
  const [isSubmit, setIsSubmit] = useState<boolean>()
  const [isEnabled, setIsEnabled] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  const [selectedPeriods, setSelectedPeriods] = useState<ClaimFiscalPeriod[]>([])
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([])
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
  const opacity = isEnabled ? '' : 'opacity-50'
  const fileInputRef = useRef<HTMLInputElement>(null);
  const promptsModule = createPromptsModule()
  const assistantModule = createAssistantModule()
  const claimsModule = createClaimsModule()
  const documentsModule = createDocumentsModule()
  const [contextDataTokensCount, setContextDataTokensCount] = useState<number>()
  const assistant = useSelector(assistantModule.assistantSelector) as AssistantState
  const fiscalPeriods = useSelector(claimsModule.fiscalPeriodsSelector) || []
  const documents = useSelector(documentsModule.documentsSelector) || []
  const fiscalPeriodsState = useSelector((state: RootState) => state.data.claimFiscalPeriods)
  const documentsState = useSelector((state: RootState) => state.data.documents)
  const isLoading = fiscalPeriodsState?.isLoading || documentsState?.isLoading

  // Color mapping for folder names
  const folderColors: { [key: string]: "info" | "gray" | "success" | "warning" | "failure" | "indigo" | "purple" | "pink" } = {
    "Technical": "info",
    "Materials": "success",
    "Financial": "warning",
    "Legal": "failure",
    "HR": "purple",
    "Marketing": "pink",
    "Operations": "gray",
    "Sales": "indigo",
    "Research": "info",
    "Development": "success",
    "Support": "warning",
    "Training": "failure",
    "Compliance": "purple",
    "Strategy": "pink",
    "Other": "gray"
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    setIsSubmit(true);
    setIsEnabled(false);
    try {
      await onUpload(selectedFiles);
    } catch (err: any) {
      console.log(err)
      setError(err?.response?.data?.error || 'Could not upload data. Please contact admin if the error persists.')
    }
    setIsSubmit(false);
    setIsEnabled(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  const fetchTokensCountDebounced = useRef(
    debounce(async (value: string) => {
      const tokensCount = await promptsModule.fetchTokensCount(value);
      return tokensCount;
    }, 500)
  ).current;

  const handleContextDataChange = async (e: any) => {
    const val = e.target.value
    onChange(e)
    setContextDataTokensCount(await fetchTokensCountDebounced(val))
  }

  const handleFiscalPeriodsChange = (periods: ClaimFiscalPeriod[]) => {
    setSelectedPeriods(periods);
    // Create a formatted string of selected periods for context
    const periodsContext = periods.map(p => p.id).join(', ');
    onChange({ target: { value: periodsContext } });
  }

  const handleDocumentsChange = (docs: Document[]) => {
    if (docs.length > 3) {
      setError('You can select up to 3 documents only.');
      return;
    }
    setSelectedDocuments(docs);
    // Create a comma-separated string of document IDs for context
    const docsContext = docs.map(d => d.id).join(',');
    onChange({ target: { value: docsContext } });
  }

  const handleRefreshDocuments = async () => {
    setIsRefreshing(true);
    try {
      await documentsModule.documentsFetcher.fetch();
    } catch (err: any) {
      setError('Failed to refresh documents. Please try again.');
    }
    setIsRefreshing(false);
  };

  useEffect(() => {
    setContextDataTokensCount(tokensCount)
  }, [tokensCount])

  useEffect(() => {
    // Reset selections when assistant changes
    setSelectedPeriods([]);
    setSelectedDocuments([]);
    
    if (assistant?.assistantId === 'claim_analyst') {
      claimsModule.claimFiscalPeriodsFetcher.fetch();
    } else if (assistant?.assistantId === 'document_analyst') {
      documentsModule.documentsFetcher.fetch();
    }
  }, [assistant?.assistantId]);

  useEffect(() => {
    setIsEnabled(!isRunning);
  }, [isRunning]);

  if (assistant?.assistantId === 'claim_analyst') {
    return (
      <div className="w-full">
        {isLoading ? (
          <div className="flex justify-center items-center p-4">
            <Spinner color="success" />
          </div>
        ) : (
          <MultiSelect<ClaimFiscalPeriod>
            label="Claims"
            value={selectedPeriods}
            options={fiscalPeriods}
            onChange={handleFiscalPeriodsChange}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            disabled={isRunning}
          />
        )}
      </div>
    );
  }

  if (assistant?.assistantId === 'document_analyst') {
    return (
      <div className="w-full">
        {isLoading ? (
          <div className="flex justify-center items-center p-4">
            <Spinner color="success" />
          </div>
        ) : (
          <div className="relative">
            <button 
              onClick={handleRefreshDocuments}
              disabled={isRefreshing || isRunning}
              className="absolute right-2 top-2 p-2 text-gray-600 hover:text-gray-900 transition-colors duration-200"
              title="Refresh documents"
            >
              {isRefreshing ? <Spinner size="sm" /> : <RefreshIcon />}
            </button>
            <MultiSelect<Document>
              label="Documents"
              value={selectedDocuments}
              options={documents}
              onChange={handleDocumentsChange}
              getOptionLabel={(option) => option.filename}
              getOptionValue={(option) => option.id.toString()}
              renderOption={(option) => {
                const maxLength = 40;
                const filename = option.filename.length > maxLength 
                  ? option.filename.substring(0, maxLength) + '...' 
                  : option.filename;
                return (
                  <div className="flex items-center gap-2">
                    <span>{filename}</span>
                    <Badge color={folderColors[option.folder_name] || "gray"} className="text-xs">
                      {option.folder_name}
                    </Badge>
                  </div>
                );
              }}
              maxItems={3}
              error={error}
              disabled={isRunning}
            />
          </div>
        )}
        {error && <ErrorToast message={error} onClose={() => { setError('') }} />}
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="w-full flex justify-between p-2">
        <span className="text font-bold text-sm md:text-xl p-1 uppercase">Context Data</span>
        <button className={`
                p-1 min-w-[160px]
                md:p-2 text-center flex justify-center items-center text-white text-[14px]
                md:min-w-[215px]
            bg-[#02C389] font rounded rounded-[15px]
            space-x-2
            ${opacity}`}
          onClick={handleButtonClick}
          disabled={!isEnabled || isSubmit || isRunning}>
          {isSubmit && <Spinner />} <UploadFileIcon /><span>Upload</span>
          <input id="file-upload" type="file"
            onChange={handleUpload}
            ref={fileInputRef}
            multiple
            accept=".txt,.csv"
            className="hidden" />
        </button>
      </div>
      <textarea id="context-data" rows={14}
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 mb-2
        rounded-lg border border-gray-300 focus:ring-[#02C389] focus:border-[#02C389]
        "
        placeholder="Enter context data..."
        onChange={handleContextDataChange}
        value={contextData}
        disabled={isRunning}
      ></textarea>
      <div className="w-full flex justify-between items-center">
        <div className=""></div>
        {contextDataTokensCount ?
          <div className="text-sm">
            <strong>{contextDataTokensCount}</strong> tokens
          </div>
          : <div></div>
        }
      </div>

      {error && <ErrorToast message={error} onClose={() => { setError('') }} />}
    </div>
  );
}

export default ContextData;